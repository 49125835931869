#seat-class-1 .airlines-tooltip {
  visibility: hidden;
}

#seat-class-1:hover .airlines-tooltip {
  visibility: visible;
}

.airlines-tooltip text {
  fill: white;
  font-size: 12px;
  font-family: sans-serif;
}

.airlines-tooltip rect {
  fill: black;
  stroke: white;
}

#seat-class-2 .airlines-tooltip {
  visibility: hidden;
}

#seat-class-2:hover .airlines-tooltip {
  visibility: visible;
}

#seat-class-4 .airlines-tooltip {
  visibility: hidden;
}

#seat-class-4:hover .airlines-tooltip {
  visibility: visible;
}

#seat-class-3 .airlines-tooltip {
  visibility: hidden;
}

#seat-class-3:hover .airlines-tooltip {
  visibility: visible;
}

.ped-tooltip {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;
}

.ped-tooltip .top {
  min-width: 100px;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 10px 10px;
  color: #fff;
  background-color: #383535;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  opacity: 0.9;
  display: none;
}

.ped-tooltip:hover .top {
  display: block;
}

.ped-tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.ped-tooltip .top i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000;
  opacity: 0.9;
}

.ped-tooltip .top p {
  margin: 0;
  font-size: 12px;
  font-family: sans-serif;
}

.ped-tooltip .top p:first-child {
  margin-bottom: 7px;
}
