$cloudbarWidth: 65px;
$footerHeight: 100px;

$colors: ( panasonic: #0067B1);

body {
  color: rgb(0, 0, 0);
  font-family: 'Inter regular', sans-serif;
  height: 100%;
  min-width: 1024px;
  overflow-x: hidden;

  min-height: 100vh;
  position: relative;
  margin: 0;


  //padding-left: $cloudbarWidth;
  padding-bottom: $footerHeight;

  background-color: #f9f9f9;
}

.platform-cloudbar-container > div:first-child {
  z-index: 1000;
}

.main-container {
  height: 100%;
  display:flex;
  margin-left: 329px;
}

.pac-footer-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 329px;
  max-width: 1080px;
}

.pac-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  width: 420vw;
  max-width: 68em;
  //width: 30vw;
  min-width: 30em;
  //max-width: 50em;
  color: #888888;

  height: $footerHeight;
  line-height: 35px;

  a {
	color: #0067B1;
  }

  .copyright {
  	padding-top: 16px !important;
  	padding-bottom: 32px !important;
  }
}

