 /******** Your Insights ******/

 .card-container {
  width: 1080px;

  .slick-arrow {
   position: absolute;
   top: 145px;
   height: 40px;
   width: 40px;
   background-color: rgba(255, 255, 255, 0.8);
   box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
   border-radius: 50%;
   border: 3px solid #4091D7;
   /* color: #4091D7; */
   font-size: 24px;
   font-weight: 700;
   line-height: 40px;
   text-align: center;
   z-index: 1;
   -webkit-transition: all .6s ease-in-out;
   transition: all .6s ease-in-out;
   opacity: 1;
   box-sizing: content-box;
  }


  .slick-slider {
   left: -40px;
  }

  .slick-prev {
   left: 42px;
  }
  .slick-prev:before {
   content: '‹';
   font-family: 'Inter Semibold', sans-serif;

  }

  .slick-next:before {
   content: '›';
   font-family: 'Inter Semibold', sans-serif;
  }
 }

 .optimize-content {
  color: white;
  position: absolute;
  top: 125px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 25px;
 }

 div.card-layout {
  width: 225px !important;
  position: relative;
  height: 296px;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff;
 }

 div.card-layout:not(:last-child) {
  margin-right: 26px;
 }

 .optimization-poster {
  background-position: left top, right top;
  background-size: 120px 190px;
  background-repeat: no-repeat;
  width: 225px;
  height: 190px;
  background-color: #2a7fc9;
  /* left: 10px; */
  margin-left: -1px;
 }

 .card-overlay {
  position: absolute;
  height: 190px;
  width: 225px;
  opacity: 0.5;
  background: rgba(0, 0, 0, 1);
  top: 55px;
  left: 0px;
 }

 .optimize-count {
  height: 63px;
  color: #ffffff;
  font-family: 'Inter Semibold', sans-serif;
  font-size: 88px;
  font-weight: 600;
 }

 .optimize-subtext {
  width: 239px;
  height: 24px;
  color: #ffffff;
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
 }

 .optimize-link {
  display: flex;
  justify-content: flex-end;
  height: 24px;
  color: #2264a0;
  font-family: "Inter Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
 }

 .optimization img {
  object-fit: none;
  height: 177px;
  filter: blur(1.5px);
 }

 .manage-media .modal-content {
  width: 700px;
  top: -150px;
  height: 370px;

 }




 /******** Your Insights ******/
 