.modal .tail-popup {
  margin-top: 12%;
  margin-left: 35%;
}

.modal .modal-content .remove-tail {
  padding: 24px !important;
}

.modal .modal-content .remove-tail .button-primary, .modal .modal-content .remove-tail .button-secondary {
  border-radius: 2px !important;
}

.modal .modal-content .remove-tail table th, .modal .modal-content .remove-tail table td {
  font-family: 'Inter SemiBold', sans-serif;
  padding: 4px 9px 4px 9px;
}

.modal .modal-content .remove-tail table tbody tr:nth-child(even) {
  background-color: #ededed;
}

.modal .modal-content .remove-tail .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
}

.modal .modal-content .remove-tail .form-body {
  margin-top: 16px;
}

.modal .modal-content .remove-tail .form-body .table {
  margin-bottom: 0;
}

.modal .modal-content .remove-tail .form-body .airline {
  margin-bottom: 6px;
  margin-top: 16px;
}

.modal .modal-content .remove-tail .form-body .airline .edit-label {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  opacity: 0.6;
}

.modal .modal-content .remove-tail .form-body .airline .value {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  opacity: 1;
  margin-left: 6px;
}

.modal .modal-content .remove-tail .buttons-section {
  float: right;
  margin-top: 32px;
}

.modal .modal-content .remove-tail .buttons-section .spinner-border {
  width: 15px;
  height: 15px;
  border-width: 1px;
}

.margin-left-16 {
  margin-left: 16px;
}