
.lopa-wizard {
	position: absolute;
	left: 0;
	top: -15px;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.95);
	/*background-color: #FFFFFF80;*/
	/*backdrop-filter: blur(4px);*/
}

.lopa-wizard-popup {
	position: absolute;
	left: calc(50% - 150px);
	top: 160px;
	//	height: 98px;
	width: 250px;
	padding: 25px;
	line-height:25px;
	background-color: white;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
	border-radius: 3px;
	box-shadow: 0 2px 9px rgba(0,0,0,0.15);
}

.lopa-wizard-button {
	margin: auto;
	margin-top: 15px;
	height: 40px;
	width: 154px;
	line-height: 40px;
	text-align: center;
	background-color: #2A7FC9;
	color: white;
	font-weight: normal;
	border-radius: 2px;
	border: 0;
	cursor: pointer;
}

.lopa-wizard-flight {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: white
}



.lopa-wizard-flight-calendar {
	position: absolute;
	left: 68px;
	top: 72px;
	border-radius: 5px;
	padding: 30px;
	height: 405px;
	width: 390px;
	/*border: none !important;*/
	.react-calendar__navigation__label__labelText--from{
		color :black !important;
		font-weight: 600;
	}
}

abbr[title] {
	text-decoration: none !important;
}

.react-calendar__navigation__arrow {
	min-width: 0!important;
	height: 37px;
	width: 37px;
	border-radius: 50%;
	font-size: 32px;
	background-color: #EDEDED !important;
}

.react-calendar__navigation button[disabled] img {
	opacity: 0.2;
}

.lopa-wizard-flight-calendar-tile {
	background-color: white;
	border-radius:50%;
	height: 45px !important;
	width: 40px !important;
	padding: 0px !important;
	margin: 0;
}


.react-calendar__tile--active:hover {
	color: black !important;
	background-color: red !important;
}

.lopa-wizard-flight-calendar-tile:hover {
	background-color:#F9F9F9 !important;
}

.lopa-wizard-flight-calendar-tile:disabled {
	color: #949494 !important;
	background-color: white !important;
}

.lopa-wizard-flight-calendar-tile:disabled:hover {
	color: #0000000F !important;
	background-color: transparent !important;
	font-weight: normal;
}

.react-calendar__navigation__label:disabled {
	background-color: white !important;
}

.react-calendar__tile--now {
	background-color: #F9F9F9 !important;
}
.react-calendar__tile--active {
	background-color: #006edc !important;
	color: white;
}
.react-calendar__month-view__days__day--weekend {
	color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575 !important;
}

.react-calendar__month-view__weekdays {
	color: #7A7A7A  !important;
	font-size: 16px  !important;
	font-weight: normal !important;
	text-transform: none !important;
	font-family: 'Inter Regular'  !important;
	text-decoration: initial !important;
}

.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__navigation__prev2-button {
	display: none;
}




.lopa-flight-picker-right {
	position: absolute;
	right: 68px;
	top: 72px;
	height: 405px;
	width: 477px;
}

.lopa-flight-list-placeholder {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: #F9F9F9;
	line-height: 405px;
	text-align: center;
}

.lopa-wizard-label {
	position: absolute;
	left: 68px;
	top: 32px;
	font-weight: bold;
}

.lopa-wizard-cancel-label {
	position: absolute;
	right: 68px;
	top: 32px;
	text-decoration: none;
	color: #2A7FC9;
	cursor:pointer;
	&:hover{
		text-decoration: underline;
	}
}

.lopa-flight-list-wrapper {
	position: absolute;
	height: 405px;
	width: 476px;
	/*	overflow: scroll;
	  white-space: nowrap;*/
	/*background-color: red;*/
	/*line-height: 405px;*/
	/*text-align: center;*/
}

.lopa-flight-list-date {
	position: absolute;
	top: 0;
	left: 18px;
	font-size: 18px;
	font-weight: bold;
}

.lopa-flight-list-search {
	position: absolute;
	top: 0;
	right: 0;
	height: 30px;
	width: 188px;
	padding-left: 12px;
	line-height: 30px;
	//color:#7a7a7a61;
	display: flex;
	border: 1px solid #7a7a7a61;
	&:focus-within{
		border: 1px solid  hsl(0,0%,20%);
	}

}
.lopa-flight-list-search-text {
	// position: absolute;
	// font-family: Inter;
	// left: 36px;
	color: hsl(0,0%,20%);
	/* color: #7a7a7a; */
	font-size: 15px;
	height: 28px;
	width: 138px;
	background-color: transparent;
	border: none;
	outline: none;
	&:focus ~ .lopa-flight-list-search{
		border: 1px solid  hsl(0,0%,20%);
	}
}

.lopa-flight-list-header {
	position: absolute;
	top: 44px;
	color: #7A7A7A;
	font-weight: 500;
}

.lopa-flight-list-header.flight {
	left: 20px;
}

.lopa-flight-list-header.departure {
	left: 110px;
}

.lopa-flight-list {
	position: absolute;
	width: 100%;
	height: calc(100% - 78px);
	top: 78px;
	overflow-y: auto;
	white-space: nowrap;
}
.lopa-loading-flights{
	margin-top: calc(50% - 100px);
	text-align:center;
}
.lopa-flight-list-table {
	width: calc(100% - 18px);
	border-collapse: collapse;
}

.lopa-flight-list-table-row {
	height: 60px;
	border-bottom: 1px solid #E0E0E0;
}

.lopa-flight-list-table-row.active{
	background-color: #F9f9f9;
}

.lopa-flight-list-table-row:last-child {
	border-bottom-width: 0px;
}

.lopa-flight-list-table-number {
	font-weight: "bold";
	width: 111px;
	padding-left: 20px;
}

.lopa-flight-list-table-time {
	width: 120px;
}

.lopa-flight-list-table-row .route {
	height: 24px;
	width: 78px;
	opacity: 0.7;
	color: #000000;
	/*font-family: 'Inter Bold', sans-serif;*/
	font-size: 15.5px;/*16px;*/
	letter-spacing: 0;
	line-height: 24px;
	font-weight: 500;
	margin-top: -4px;
}

.lopa-flight-select {
	display: none;
	height: 40px;
	width: 174px;
	float: right;
	text-align: center;
	line-height: 40px;
	background-color: #2A7FC9;
	color: white;
	border-radius: 2px;
	cursor: pointer;
	margin-right: 20px;
}

.lopa-flight-select.active {
	display: block;
}

.flight-details {
	height: 41px;
	width: 890px;
	margin-left: 72px;
	margin-right: 72px;
	display: flex;
	flex-wrap: wrap;
	justify-content:space-between;

	.flight-detail {
		position: relative;
		height: 41px;
/*		min-width: 80px;
		max-width: 150px;*/
	}

	.text {
		height: 24px;
		color: #000000;
		font-family: "Inter",sans-serif;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
	}

	.category {
		height: 17px;
		opacity: 0.6;
		color: #000000;
		font-family: "Inter",sans-serif;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
	}

	.change-flight-link {
		height: 24px;
		/*width: 102px;*/
		color: #2264A0;
		font-family: "Inter",sans-serif;
		font-size: 16px;
		letter-spacing: 0;
		line-height: 44px;
		cursor: pointer;
		/*text-align: right;*/
 		&:hover{
 			text-decoration: underline;
 		}
	}
}

.flight-detail-separator {
	margin: 30px 31px 30px 24px;
	height: 1px;
	border: 0.5px solid #eeeeee;
}

.lopa-flight-info {
	display: inline-block;
	margin-left: 26px;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
}
.lopa-flight-duration{
	position: absolute;
	right:50px;
	font-weight: 500;
	font-size: 18px;
}
.lopa-no-flights-found{
	height:100%;
	width:100%;
	display:flex;
	justify-content:center;
	flex-direction:column;
	text-align:center;
	color: rgba(0,0,0,0.6);
	.no-flight-date{
		font-size: 18px;
		font-weight: bold;
		color: #000000;
		margin-top:5px;
	}
	.select-another{
		font-size: 16px;
		letter-spacing: 0;
		line-height: 20px;
		margin-top:20px;
		color: #000000;
	}
}
.lopa-change-flight {
	display: inline-block;
	margin-left: 16px;
	color: #2A7FC9;
	text-decoration: none;
	cursor: pointer;
	transition:0.5s;
	&:hover{
		text-decoration: underline;
	}
}

.lopa-legend {
	/*position: absolute;*/
	/*top: 0px;*/
	/*right: 28px;*/
	float: right;
	margin-right: 6px;
	/*margin-bottom: 32px;*/
}

.lopa-wrapper {
	position: relative;
	left:0;
	/*height: 128px;*/
	/*margin-top: 48px;*/
	width: 100%;
	height: 450px;
}

.lopa-legend-item {
	display: inline-block;
	height: 32px;
	width: 80px;
	font-weight: bold;
	color: #01325380;
}

.lopa-legend-item div{
	height: 16px;
}

.seat-letters {
	position: absolute;
	left: 26px;

	/*top: 28px;*/
	top: calc( 50% - 10px );
	transform: translateY(-50%);
	/*padding-top: 4px;*/
	/*background-color: white;*/
	box-shadow: 15px 0px 3px white;
}

.seat-letter {
	/*height: 25px;*/
	height: 30px;
	width: 17px;
	/*line-height: 25px;*/
	line-height: 30px;
	text-align: center;
	color: #000000;
	opacity: 0.5;
	font-weight: bold;
	/*margin-bottom: 8px;*/
	margin-bottom: 3px;
}

.lopa-grid-wrapper {
	position: absolute;
	margin-left: 49px;
	width: calc(100% - 80px);
	/*top: calc( 50% - 30px );*/
	top: 225px;
	height: 100%;
	transform: translateY(-50%);
	padding-left: 7px;
	padding-right: 7px;
	overflow-x: auto;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.lopa-grid-wrapper::-webkit-scrollbar {
	display: none;
}

.lopa-tool-tip {
	position: relative;
	transform: scale(0.735);
	border-radius: 3px;
	background-color: white;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	color: black;
	text-shadow: none;
	padding: 0px 0px 0px 0px;
	width: 280px;
	position: relative;
	.lopa-tool-tip-container{
		position:relative;
	}
}

.lopa-tool-tip.hidden {
	display: none;
}
.lopa-tool-tip-seatNumber{
	color: #10304C;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0.33px;
	line-height: 45px;
	text-align: center;
	width:100%;
	background:#F9F9F9;

}
.lopa-tool-tip-noData-seatNumber{
	text-align: center;
	color: #10304C;
	height: 48px;
	width: 250px;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 0.33px;
	line-height: 45px;
	background:#F9F9F9;
	width:100%;
}
.lopa-tool-tip-noData-header{
	margin-top:10px;
	color: #10304C;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.26px;
	line-height: 20px;
	width:100%;
	text-align:center;
	padding:20px 0px;
}
.lopa-tool-tip-no-data {
	width: calc( 100% - 24px);
	color: #10304C;
	font-family: Inter;
	font-size: 24px;
	font-weight: bold;
	line-height: 29px;
	padding: 12px;
	white-space: initial;
	text-align: center;

}

.lopa-tool-tip-header {
	margin:24px 0px 10px 0px ;
	color: #10304C;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.26px;
	line-height: 20px;
	width:100%;
	text-align:center;
}
.lopa-tool-tip-header-hours{
	color: #10304C;
	font-weight: normal;
	/*letter-spacing: 0.39px;*/
	line-height: 25px;
	text-align: center;
}
.lopa-tool-tip-header-value {
	color: #10304C;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.39px;
	line-height: 29px;
	width:100%;
	text-align:center;
	margin:10px 0px 12px 0px;
}
.lopa-tool-tip-header-light{
	margin-top:5px;
	color: #7A7A7A;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	width:100%;
}
.lopa-tool-tip-data {
	font-size: 16px;
	overflow-x: hidden;
	list-style: none;
	padding: 0px 0 16px 0;
	margin: 10px 18px 0 18px;
	height:auto;
}

.lopa-tool-tip-data li:before {
	float: left;
	width: 0;
	white-space: nowrap;
	color: #979797;
	content: ". . . . . . . . . . . . . . . . . . . . . . . . . "
}

.lopa-tool-tip-data li {
	text-align: left;
}

.lopa-tool-tip-data-name {
	background: white;
	color: #7A7A7A;
	padding-right: 0.3em;
	padding-top:16px;
	padding-bottom:16px;
	line-height: 32px;
}

.lopa-tool-tip-data-value {
	float: right;
	background: white;
	padding-left: 0.3em;
	color: #10304C;
	font-weight: 600;

}


.lopa-grid {
	position: absolute;
	top: calc( 50% - 30px );
	transform: translateY(-50%);
	user-select: none;
	display: grid;
	grid-gap: 3px;
	grid-auto-flow: column;
	margin-top: 0;
	margin-left: 3px;
}

.lopa-number {
	text-align: center;
	color: #00000080;
	font-weight: bold;
}

.lopa-seat-wrapper {
	box-sizing: border-box;
	height: 30px;
	width: 30px;
	color: #e83717;
}

.lopa-seat {
	box-sizing: border-box;
	height: 30px;
	width: 30px;
	color: transparent;
	transition: all 0.05s ease-in-out;
	font-size: 11px;
	line-height: 26px;
	text-align: center;
	border-width: 2px;
	border:1px solid rgba(255,255,255,0.5);
	cursor: pointer;

}

.lopa-seat.active {
	transform: scale(1.36);
	color: #000000;
	border: 1px solid rgba(255,255,255,0.5);
	box-shadow: 0 2px 9px 0 rgba(0,0,0,0.2);
}

.lopa-buffer {
	height: 25px;
	width: 25px;
}

.lopa-grid-scroller {
	position: absolute;
	left: 50%;
	bottom: 10px;
	transform: translateX(-50%);

}

.lopa-grid-scroll-arrow {
	display: inline-block;
	font-size: 32px;
	color: #4D98D9;
	margin: 0 25px 0 25px;
	cursor: pointer;
	user-select: none;
}

.lopa-grid-scroll-dot {
	display: inline-block;
	height: 11px;
	width: 11px;
	border-radius: 50%;
	margin: 0 5px 4px 5px;
	border: 1px solid #4091D7;
	box-sizing: border-box;
	cursor: pointer;
}

.lopa-grid-scroll-dot.active {
	background-color: #4091D7;
}

.no-usage{
	background-color: transparent;
}

.lopa-flight-list-search-icon{
	height: 20px;
	width: 20px;
	margin: auto;
}