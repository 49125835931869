.slick-dots {
	display: flex;
	justify-content: center;
	list-style-type: none;
	bottom: -10px !important;
	position: relative;
}

.slick-dots button {
	text-indent: -9999px;
	/*pointer-events: none;*/
}

.slick-track {
	margin-left: 0px;
	margin-right: 0px;
}

.slick-dots li {
	background-color: transparent;
	border-radius: 11px;
	height: 12px !important;
	width: 12px !important;
	border: 1px solid #4091D7;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

/*.slick-dots li:hover {*/
/*	background-color: blue;*/
/*}*/

.slick-dots li.slick-active{
	background-color: #4091D7;
}

/*
.carousel {
    height: 30px;
    width: 100%;
    margin-left: -16px;
    position: relative;
}
*/

.carousel-wrapper {
	height: 340px;
	/*width: calc(100% - 56px);*/
	margin: 0 8px;
}

.carousel-wrapper::-webkit-scrollbar {
	height: 0;
}

.carousel::-webkit-scrollbar {
	height: 0;
}

.carousel-arrow {
	position: absolute;
	bottom: -20px;
	height: 40px;
	width: 40px;
	color: #4091D7;
	font-size: 36px;
	font-weight: 700;
	line-height: 40px;
	text-align: center;
	z-index: 100;
	transition: all .6s ease-in-out;
	opacity: 1;
	box-sizing: content-box;
}

.carousel-arrow:hover {
	background-color: rgba(255, 255, 255, 1);
}

.carousel-arrow.hide {
	opacity: 0.5;
	cursor: default;
}

.carousel-arrow.show {
	opacity: 1;
	cursor: pointer;
}

.carousel-arrow.right {
	right: calc(50% - 70px);
}

.carousel-arrow.left {
	left: calc(50% - 70px);
}

.carousel-item {
	margin: 0 14px;
	height: 320px;
	/*width: 167px;*/
	display: initial;
	/*display: inline-block;*/
}

.carousel-flag-wrapper {
	width: 40px;
}

.carousel-flag {
	position: absolute;
	top: 0;
}

.carousel-flag-number {
	position: absolute;
	top: 16px;
	right: 14px;
	color: white;
	font-weight: 600;
	font-size: 48px;
	/*text-shadow: 0 2px 4px 0 rgba(255,0,0,0.72);*/
	text-shadow: rgba(0,0,0,0.72) 0px 2px 4px;
	line-height: 37px;
	text-align: center;
	cursor: default;
}

.carousel-image-wrapper {
	position: relative;
	height: 248px;
	width: 167px;
	border: 1px solid #e3e3e3;
	border-radius: 15px !important;
	box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.12);
	cursor: pointer;
}

.carousel-alt-title {
	position: absolute;
	top: 55%;
	/*margin-top: -72px;*/
	/*font-size: 18px;*/
	font-size: 16px;
	width: 167px;
	color: #b4d0ed;
	text-align: center;
	font-weight: 700;
	word-wrap: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 45%;
	padding-left: 3px;
	padding-right: 3px;
}

.carousel-image-wrapper.ereader  .carousel-placeholder-image {
	top: 55%;
	left: 50%;
}

.carousel-placeholder-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-100%);
}

.carousel-item-image {
	display: block;
	position: absolute;
	/*object-fit: cover; */
	height: 248px;
	width: 165px;
	border-radius: 15px;
	opacity: 0.01;
	/*box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.5);*/
}

.carousel-item-image-shadow {
	box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.45);
	opacity: 1;
}

.audio .carousel-item-image, .live-tv .carousel-item-image, .ereader .carousel-item-image {
	height: 167px;
}

.title-data-wrapper {
	width: 170px;
	height: 190px;
	margin-top: 15px;
	margin-left: 10px;
	background-color: whitesmoke;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-right: 10px
}

.title-data-values {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	/*margin-right: 35px;*/
	color: darkslategray;
	margin-top: 20px;
	font-size: 31px;
	font-weight: bold;
	align-items: center;

}

.trend, .trend i {
	font-size: 15px;
	padding-top: 10px;
}
.down-trend {
	color: red;
}

.up-trend {
	color: green;
}

.data-title {
	color: gray;
	font-size: 14px;
	padding-bottom: 12px;
}

.carousel-item-title {
	font-size: 15px;
	font-weight: 700;
	line-height: 20px;
	padding-top: 12px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 170px;
}

.carousel-item-data {
	font-size: 13.4px;
	line-height: 20px;
	float: left;
	color:#616161;
}

.carousel-item-change {
	font-size: 13.4px;
	line-height: 20px;
	float: right;
	margin-right: 24px;
	font-weight: 600;
}
.carousel-item-change .arrow{
	font-size: 12px;
	padding: 2px 3px;
	line-height: 14px;
}

.carousel-no-data {
	position: absolute;
	margin-top: -20%;
	margin-left: 35%;
	font-size: 26px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.65);
}

.blurred {
	filter: blur(5px);
}

.cursor-default {
	cursor: default;
}

/*.carousel-no-data {
	text-align: center;
	margin-top: 10%;
	font-size: 26px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.65);
}*/

/*.carousel-pagination {
	height: 10px;
	position: absolute;
	bottom: 0;
	left: 50%;
	display: grid;
	grid-gap: 8px;
	grid-auto-flow: column;
}

.carousel-page {
	background-color: transparent;
	border-radius: 10px;
	height: 10px;
	width: 10px;
	border: 1px solid #00539d;
	cursor: pointer;
	transition: all .4s ease-in-out;
}

.carousel-page.active {
	background-color: #00539d;
}*/