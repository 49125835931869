.modal .release-notification-popup {
  margin-top: 12%;
  margin-left: 33%;
}

.modal .release-notification-popup .modal-content {
  width: 552px;
}

.modal .modal-content .release-notification {
  padding: 24px !important;
}

.modal .modal-content .release-notification .features-wrapper {
  padding: 0 15px 0 15px;
}

.modal .modal-content .release-notification .features-wrapper h1 {
  font-size: 14px;
  margin-left: 8px;
  line-height: 16px;
}

.modal .modal-content .release-notification .features-wrapper .content {
  overflow: hidden;
  padding-right: 0px;
  width: 100%;
  overflow-x: hidden;
  height: 302px;
}

.modal .modal-content .release-notification .wrapper {
  margin-left: 18px;
  margin-right: 18px;
}

.modal .modal-content .release-notification .wrapper .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
  color: #000000; /*#194A74;*/
}

.margin-left-16 {
  margin-left: 16px;
}

.release-notification .button-secondary, .release-notification .button-primary {
  border-radius: 0 !important;
  float: right;
  margin-top: 24px;
}

.modal .modal-content .release-notification .wrapper .form-body {
  margin-top: 8px;
}

.modal .modal-content .release-notification .buttons-section {
  float: none;
  margin-bottom: 2px;
  margin-top: 12px;
}

.modal .modal-content .release-notification .buttons-section .button-primary {
  left: 38%;
  position: relative;
  border-radius: 2px !important;
}

.modal .modal-content .release-notification .wrapper .form-body .note {
  margin-top: 0px;
  margin-bottom: 12px;
}