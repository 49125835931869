.tooltipContainer{
  position: absolute !important;
  padding:45px 27px 24px 24px;
  right: 56px;
  top: 20px;
  width: 435px; // 675px;
  height: auto;
  transition: all .25s ease-in-out;
  z-index: 101;
  border-radius: 10px;
  backdrop-filter: blur(250px);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  /*background: linear-gradient(180deg, rgba(252,254,238,0.5) 0%, rgba(230,255,244,0.5) 100%);*/
  /*background-color: #ffffff;*/
  background-color: #ffffff;
  .closeBtn{
	position: absolute;
	top: 28px;
	right: 22px;
	cursor:pointer;
	.close-arrow{
		position:relative;
		 height:16px;
  	 width:16px;
		.arrow-left{
		  width:100%;
		  height:1px;
		  background-color:black;
		  transform:rotate(45deg);
		  margin:auto;
		  position:absolute;
		}
		.arrow-right{
		  width:100%;
		  height:1px;
		  background-color:black;
		  transform:rotate(-45deg);
		  margin:auto;
		  position:absolute;
		}
	}
  }
  
 .tooltipBody::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
    overflow: hidden;
    margin-right:5px;
}
  .tooltipBody::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 4px;
}
  .tooltipBody{
	display: flex;
	flex-direction: column;
	width: 100%;
	max-height: 400px;
	overflow-y:auto;
	margin-top: 10px;
	.paragraph{
	  font-size: 16px;
	  font-weight: 500;
	  margin-right: 10px;
	  margin-bottom: 0;
	  p{
		margin: 0px 0 5px 0;
		padding:0px;
	  }
	  h1{
		font-size: 18px;
		margin-top: -7px;
		padding-bottom:20px;
		font-weight:bold;
		position: absolute;
		top:24px;
		left:24px;
		max-width: 84%;
	  }
	  ul{
		height: auto;
		display: inline-table; 
		margin-left: 1em;
	  }
	  ul li {
		list-style-type: disc;
	  }
	}
  }
}

.tooltipContainer.carousel, .tooltipContainer.plane, .tooltipContainer.trends, .tooltipContainer.vertical-bar, .tooltipContainer.screen-transition, .tooltipContainer.lopa, .tooltipContainer.table {
  width: 680px;
}

.tooltipContainer.carousel.audio > .tooltipBody, .tooltipContainer.carousel.games > .tooltipBody, .tooltipContainer.carousel.live-tv > .tooltipBody {
  max-height: 320px;
  overflow-y:auto;
}