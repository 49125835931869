.table-widget {
     .table-outer-container {
     
         .table-container {
             box-sizing: border-box;
             width: 100%;
             border: 1px solid rgba(0, 0, 0, 0.1);
             border-radius: 2px;
             margin-top: 14px;
             overflow: auto;
     
             table {
                 width: 100%;
                 border-collapse: separate;
                 border-spacing: 0;
     
                 td,
                 th {
                     white-space: nowrap;
                 }
     
                 .sticky-left {
                     position: sticky;
                     left: 0;
                     /* Add space before first td/th when overlow = initial */
                     z-index: 2;
                     background: inherit;
                 }
     
                 thead {
                     height: 20px;
                     background-color: #fcfcfc;
                     
                     tr {
                         height: 20px;
                     }
                     
                     th {
                         height: 18px;
                         color: #4b4b4b;
                         font-family: 'Inter', sans-serif;
                         font-size: 16px;
                         font-weight: normal;
                         letter-spacing: 0;
                         line-height: 24px;
                         text-align: left;
                         position: relative;
                         background-color: #fcfcfc;
                         padding-top: 5px;
                         padding-bottom: 5px;
                         border-bottom: 1px solid #dee2e6;
                     }
     
                     th:first-child {
                         padding-left: 24px;
                     }
     
                     th:last-child {
                         padding-right: 24px;
                     }
     
                     th.sticky-left,
                     th.sticky-left-2,
                     th.sticky-right {
                         background-color: #fcfcfc;
                     }
                 }
     
                 tbody {
                     tr {
                         height: 35px;
     
                         td {
                             height: 25px;
                             color: #000000;
                             font-family: 'Inter', sans-serif;
                             font-size: 16px;
                             letter-spacing: 0;
                             line-height: 24px;
                             text-align: left;
                             padding-left: 7px;
                             padding-right: 7px;
                             position: relative;
                             padding-top: 3px;
                             padding-bottom: 3px;
                         }
     
                         td:first-child {
                             padding-left: 24px !important;
                         }
     
                         td:last-child {
                             padding-right: 24px;
                         }
                     }
     
                     tr:nth-child(even) {
                         background: #fcfcfc;
                     }
     
                     tr:nth-child(odd) {
                         background: #ffffff;
                     }
     
                     tr:nth-child(even) td {
                         background: #fcfcfc;
                         padding-left: 14px;
                     }
     
                     tr:nth-child(odd) td {
                         background: #ffffff;
                         padding-left: 14px;
                     }
                 }
             }
         }
     
         .card {
             box-sizing: border-box;
             border: 1px solid rgba(0, 0, 0, 0.1) !important;
             border-radius: 2px;
             margin: 0;
         }
     
         .card-header {
             border-bottom: none !important;
             padding: 16px 16px !important;
             box-shadow: none !important;
         }
     
         .card-body {
             padding: 0px 24px 24px 36px !important;
     
             .row:nth-child(1),
             .row:nth-child(2) {
                 margin-right: 0 !important;
                 margin-left: 0 !important;
             }
         }
     
         .card-title {
             width: 97%;
             color: #000000 !important;
             font-size: 16px !important;
             font-family: 'Inter', sans-serif;
             font-weight: 600;
             display: inline-flex;
             margin-bottom: 0;
     
             span {
                 font-weight: normal !important;
                 color: #7a7a7a !important;
                 padding-left: 5px !important;
             }
         }
     
         .accordion {
             width: 100%;
             margin-bottom: 15px !important;
         }
     
         .accordion-button {
             text-align: left;
             border: none;
             background: none;
             padding: 0;
         }
     
         .fa-container {
             width: 20px !important;
         }
     
         .table-container::-webkit-scrollbar {
             overflow: hidden;
             background-color: #ededed;
             height: 10px;
         }
     
         .table-container::-webkit-scrollbar-thumb {
             background: #adadad;
             border-radius: 25px;
             height: 10px;
         }
     
         .table-container.loading {
             height: 300px;
             padding-top: 9%;
             text-align: center;
             background-color: #ffffff;
         }
     
         .table-container.data-not-available {
             height: 300px;
             padding-top: 70px;
             text-align: center;
     
             .no-airline-title {
                 font-family: 'Inter Bold', sans-serif;
                 color: #616161;
                 font-size: 18px;
                 margin-bottom: 16px;
                 margin-top: 40px;
             }
     
             .no-airline-body {
                 font-family: 'Inter Regular', sans-serif;
                 color: #616161;
                 font-size: 16px;
                 margin-bottom: 32px;
             }
     
             .no-tail-title {
                 font-family: 'Inter Bold', sans-serif;
                 color: #616161;
                 font-size: 18px;
                 margin-bottom: 16px;
             }
     
             .no-tail-body {
                 font-family: 'Inter Regular', sans-serif;
                 color: #616161;
                 font-size: 16px;
                 margin-bottom: 32px;
             }
         }
     
         .pad-top-license {
             padding-top: 19px;
         }
     
         .pagination-container {
             width: 100% !important;
             font-family: 'Inter regular', sans-serif;
     
             * {
                 font-family: inherit;
             }
             
             .options {
                 z-index: 3;
             }
         }
     
         .widget-overflow {
             top: 14px;
             right: 16px;
         }
     
         .widget-overflow-popup {
             top: 8px;
             width: 102px;
             border-radius: 6px;
             box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
         }
 
         .widget-overflow-label {
             padding-left: 16px;
         }
     
         .widget-overflow-option {
	             width: 102px;
             color: #616161;
         }
     
         .icon-check-mark {
             font-size: 12px;
             color: #2A7FC9;
         }
     }
}
 
.widget-frame.table {
    min-height: 540px;
  	max-height: 540px;
  	margin-bottom: 0px;
}
 
.widget-body.table {
  	margin-bottom: 0px;
  	margin-right: 10px;
 	margin-left: 25px;
 	width: 95%;
}

.main-content {
    .custom-loading.table {
        margin-top: 15%;
    }
}
