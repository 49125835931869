.pill-box {
    position: relative;
    height: 83px;
    width: 504px;
    background-color: #F0F2F5;
    border-radius: 15px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.50);
}

.pill-box-background-fill {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    /*width: 60%;*/
    border-radius: 15px 0 0 15px;
}

.pill-box-data {
    position: absolute;
    top: 16px;
    height: 40px;
    width: 100%; /*307px todo: toggle width after average completion rate is not available */
    text-align: center;
    font-size: 36px;
}

.vertical-middle {
    vertical-align: middle;
}

.pill-box-title {
    position: absolute;
    bottom: 4px;
    color: #000000;
    /*opacity: 0.75;*/
    font-size: 16px;
    text-align: center;
    width: 100%; /*307px todo: toggle width after average completion rate is not available */
    height: 26px;
}

.pill-box-container {
    margin-top: 25px;
    padding-left: 5px;
    display: flex;
    grid-gap: 18px;
    grid-auto-flow: column;
    width: calc(100% - 20px);
}

.line-chart-container {
    margin-top: 20px;
    display: flex;
    grid-gap: 12px;
    grid-auto-flow: column;
}

.audio  .trend-image{
    height:167px;
    position: relative
}
.audio .trend-item-image{
    height:167px;

}

.audio  .trend-alt-title{
    top:null;
    bottom: 10px;
    margin-top:-5px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.audio .trend-placeholder-image{
    top:50%;
    margin-left:35%;
}

.audio .trend-col{
    margin-top:50px;
}


.ereader  .trend-image{
    height:167px;
    position: relative
}
.ereader .trend-item-image{
    height:167px;

}

.ereader  .trend-alt-title{
    top:null;
    bottom: 10px;
    margin-top:-5px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.ereader .trend-placeholder-image{
    top:58%;
    margin-left:35%;
}

.ereader .trend-col{
    margin-top:50px;
}

.live-tv  .trend-image{
    height:167px;
    position: relative
}
.live-tv  .trend-item-image{
    height:167px;

}

.live-tv   .trend-alt-title{
    top:null;
    bottom: 10px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top:-5px;

}
.live-tv  .trend-placeholder-image{
    top:50%;
    margin-left:35%;
}

.live-tv  .trend-col{
    margin-top:50px;
}

.games  .trend-image{
    height:248px;
    position: relative
}
.games  .trend-item-image{
    height:248px;

}

.games  .trend-alt-title{
    top:null;
    bottom: 10px;
    margin-top:-5px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.games  .trend-placeholder-image{
    top:50%;
    margin-left:35%;
}

.movies  .trend-image{
    height:248px;
    position: relative
}
.movies  .trend-item-image{
    height:248px;

}

.movies  .trend-alt-title{
    top:null;
    bottom: 10px;
    margin-top:-5px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.movies  .trend-placeholder-image{
    top:50%;
    margin-left:35%;
}

.movies  .trend-col{
    margin-top:50px;
}
.movies  div[ id="big" ].trend-col {
    margin-top:30px;
}


.tv-series  .trend-image{
    height:248px;
    position: relative
}
.tv-series  .trend-item-image{
    height:248px;

}

.tv-series  .trend-alt-title{
    top:null;
    bottom: 10px;
    margin-top:-5px;
    display: -webkit-box;
    max-width: 167px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.tv-series  .trend-placeholder-image{
    top:50%;
    margin-left:35%;
}

.tv-series  .trend-col{
    margin-top:50px;
}
.tv-series  div[ id="big" ].trend-col {
    margin-top:30px;
}
.modal-body {
    font-family: "Inter Regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 30px 38px 54px 54px !important;
}

.child-data-container {
    margin-top: 47px;
    padding: 24px 24px 5px 24px;
    width: calc(100% - 20px);
    /*width: 946px;*/
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    background-color: #FFFFFF;
}

.child-data-table {
    margin-top: 24px;
    margin-left: 0;
    width: 840px;
}

.child-data-table .child-table-row:nth-child(1) {
    margin-top: 0;
}

.child-data-table .child-table-row {
    margin-top: 16px;
}

.child-item-bar {
    width: 450px;
    padding-left: 55px;
    padding-bottom: 24px;
}

.child-item-title {
    width: 235px;
    max-width: 300px;
    padding-bottom: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.child-item-title div {
    width: 325px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.child-data-header {
    height: 24px;
    color: #000000;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

.media-detail-container {
    height: 460px;
    overflow-y: auto;
}

.media-detail-container::-webkit-scrollbar {
    width: 7.3px;
    border-radius: 4px;
    overflow: hidden;
    margin-right:5px;
}
.media-detail-container::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 4px;
}
.media-detail-container::-webkit-scrollbar-track {
    background: #D8D8D8;
    border-radius: 4px;
}