.manage-detail .modal-content{
     max-width: 1066px;
    min-width: 1066px;
    min-height: 560px;
    top: -330px;
    left: -95px;
    border: none;
    box-shadow: 0 1px 8px #979797;
    border-radius: 2px;
    background-color: #ffffff;
    .modal-header {
        padding: 0;
        height: 0;
        h5.modal-title {
            color: white;
        }
    }
    
    .close {
        font-size: 56px;
        font-weight: lighter;
        opacity: 0.7;
        /*margin-top: -30px;*/
        padding: 0;
        cursor: pointer;
        outline: none;
        margin-right: -2.2px;
    }
    .close:before, .close:after {
        display: none;
    } 
    
    .carousel-placeholder-image {
            top: 27%;
    left: 12%;
    }
}