.vertical-bar.widget-frame {
  height: 655px;
}

.vertical-bar-chart-tooltip {
  position: absolute;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 2px 0 #CFC9C9;
  z-index: 1;
  color: #000000;
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
}

.vertical-bar-chart-tooltip th {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
  min-width:130px;
   padding: 0px 0px 5px 8px;
}
.vertical-bar-chart-tooltip tr td .value{
  float:right;
  min-width:35px;
  text-align: left;
}
.vertical-bar-chart-tooltip tr td .description{
  float:left;
  text-align: left;
  padding-right:10px;
}
.vertical-bar-chart-tooltip tr td {
  padding: 0px 0px 5px 8px;
  font-size: 16px;
}

.vertical-bar-legend {
  position: absolute;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  bottom: -70px;
  left: 130px;
  height: 64px;
  width: 100%;
}

.vertical-bar-yaxis-label {
  margin-left: 45px;
  margin-bottom: 20px;
  color: #a9a9a9;
  font-size: 14px;
  text-transform: capitalize;
}



.vertical-bar-legend {

  .vertical-bar-chart-legend-color {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    user-select: none;
    cursor: pointer;
  }

  .vertical-bar-chart-legend-label {
    display: inline-block;
    margin-left: 10px;
    line-height: 24px;
    vertical-align: top;
   font-family: 'Inter', sans-serif;
    cursor: pointer;
  }
}