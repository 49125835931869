.donut-chart-wrapper {
	width: 100%;
}

.donut-chart {
	display:inline-block;
	margin-left: 50%;
	transform: translateX(-50%);
	width: 66%;
}

.donut-legend {
	position: absolute;
	top: 53%;
	left: 50%;
	width: 200px;
	height: 115px;
	transform: translate(-50%,-50%);
	font-family: 'Inter', sans-serif;
	pointer-events: none !important;
}

.donut-legend.small {
	position: absolute;
	top: 54%;
	left: 55%;
	width: 160px;
	height: 110px;
	transform: translate(-50%,-50%);
	font-family: 'Inter', sans-serif;
	pointer-events: none !important;
}

.donut-legend-item {
	position: absolute;
	left: 0px;
	top: -21px;
	overflow: hidden;
	/*color: #000000;*/
	color: #616161;
	height: 24px;
	font-family: 'Inter', sans-serif;
	letter-spacing: 0;
	line-height: 24px;
	width: 200px;
	/*opacity: 0.7;*/
	/*width: 170px;*/
	font-size: 20px;
	text-align: center;
	/*font-weight: bold;*/
	white-space: nowrap;
	text-overflow: ellipsis;
}

.donut-legend-item.small {
	top: 3px;
	left: 24px;
	font-size: 16px;
	width: 90px;
}

.donut-legend-item-basic {
	position: absolute;
	left: 0px;
	top: -12px;
	overflow: hidden;
	/*color: #000000;*/
	color: #616161;
	height: 24px;
	font-family: 'Inter', sans-serif;
	letter-spacing: 0;
	line-height: 24px;
	width: 200px;
	/*opacity: 0.7;*/
	/*width: 170px;*/
	font-size: 20px;
	text-align: center;
	/*font-weight: bold;*/
	white-space: nowrap;
	text-overflow: ellipsis;
}

.donut-legend-item-basic.small {
	top: 5px;
	left: 24px;
	font-size: 16px;
	width: 90px;
}

.donut-legend-number {
	position: absolute;
	left: -5px;
	top: 24px;
	overflow: hidden;
	color: #000000;
	width: 220px;
	font-size: 53px;
	text-align: center;
	font-weight: bold;
	pointer-events: none !important;
}

.donut-less-than-sign {
	position: absolute;
	overflow: hidden;
	color: #000000;
	text-align: center;
	font-weight: bold;
	pointer-events: none !important;
	width: 55px;
    font-size: 25px;
    top: 45px;
    left: 15px;
}

.donut-less-than-sign-basic {
	position: absolute;
	overflow: hidden;
	color: #000000;
	text-align: center;
	font-weight: bold;
	pointer-events: none !important;
	width: 55px;
    font-size: 25px;
    top: 29px;
    left: 15px;
}

.donut-legend-number.small {
	top: 36px;
	left: -3px;
	width: 150px;
	font-size: 44px;
	pointer-events: none !important;
}

.donut-legend-number.time {
	left: -15px;
	top: 18px;
	font-size: 40px;
}

.donut-legend-number-basic {
	position: absolute;
	left: -5px;
	top: 8px;
	overflow: hidden;
	color: #000000;
	width: 220px;
	font-size: 53px;
	text-align: center;
	font-weight: bold;
	pointer-events: none !important;
}

.donut-legend-number-basic.small {
	top: 26px;
	left: -3px;
	width: 150px;
	font-size: 44px;
	pointer-events: none !important;
}

.donut-legend-change {
	position: absolute;
	left: 0px;
	top: 88px;
	overflow: hidden;
	color: #000000;
	width: 200px;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
}

.donut-legend-change.small {
	font-size: 16px;
	width: 90px;
	left: 25px;
	top: 95px;
}

.donut-legend-change-basic {
	position: absolute;
	left: 0px;
	top: 81px;
	overflow: hidden;
	color: #000000;
	width: 200px;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
}

.donut-legend-change-basic.small {
	font-size: 16px;
	width: 90px;
	left: 25px;
	top: 90px;
}

.donut-legend-change-metric {
	position: absolute;
	left: 0px;
	top: 2px;
	overflow: hidden;
	color: #000000;
	width: 200px;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
}

.donut-legend-change-metric.small {
	font-size: 16px;
	width: 90px;
	left: 25px;
	top: 21px;
}

.donut-legend-data {
	margin-top: 10%;
	height: 28px;
	line-height: 28px;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.39px;
}

.donut-legend-make {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-model {
	font-size: 12px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-legend-defaultKey {
	font-size: 20px;
	text-align: center;
	line-height: 20px;
	height: 20px;
}

.donut-others {
	margin-left: calc(50% - 41px);
	width: 72px;
	height: 22px;
	border-radius: 12px;
	background-color: #82a6cc;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	text-align: center;
	line-height: 20px;
	padding: 2px 0 0 3px;
	letter-spacing: 0.39px;
	font-family: "Inter regular", sans-serif;
}

.donut-legend-outer {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: #616161;
	margin-bottom: 5px;
}

.donut-legend-outer.short-label {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: #616161;
	margin-bottom: 5px;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding-left:10%;
    padding-right:10%;
}


/* Style for bottom Legends - starts */

.donut-legend-bottom {
	position: absolute;
	width: 455px;
	height: auto;
	transition: all 0.25s ease-in-out;
	left: 0;
	margin: 0 24px 0 24px;
	box-sizing: border-box;
	/*bottom: -44px;*/
}

.donut-legend-bottom-tab {
	bottom: -1px;
	height: 36px;
	width: 118px;
	padding: 8px 13px 10px 17px;
	background-color: rgba(249,249,249,0.9);
	box-shadow: 0 -5px 8px -5px rgb(0 0 0 / 12%);
	border: 1px solid rgba(0,0,0,0.1);
	border-bottom: none;
	color: #616161;
	font-family: 'Inter', sans-serif;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	position: relative;
	transition: all 0.25s ease-in-out;
}

.donut-legend-bottom-tab:closed{
	padding: 10px 13px 4px 17px;
}

.donut-legend-bottom-tab:hover{
	background-color: #e5e5e5;
}

.donut-legend-bottom-item-container {
	background-color: rgba(249,249,249,0.9);
	/*box-shadow: 0 -2px 8px -4px rgb(0 0 0 / 9%);*/
	box-shadow: 0 -5px 8px -5px rgb(0 0 0 / 12%);
	border: 1px solid rgba(0,0,0,0.1);
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	padding-bottom: 15px;
	padding-left: 15px;
	overflow: hidden;
	transition: all .25s ease-in-out;
}

.donut-legend-bottom-item-container.closed {
	padding-bottom: 0;
	/*border: none;*/
	height: 0;
	/*transform: scale(0);*/
	color: transparent;
}

.donut-legend-bottom-item {
	height: 20px;
	margin: 15px 0 0 0;
}

.donut-legend-bottom-item-color {
	display: inline-block;
	height: 20px;
	width: 20px;
}

.donut-legend-bottom-item-label {
	display: inline-block;
	color: #616161;
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 20px;
	margin-left: 10px;
	vertical-align: top;
}

.donut-legend-bottom-item-label.short-label {
	display: inline-block;
	color: #616161;
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 20px;
	margin-left: 10px;
	vertical-align: top;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
}

/* Style for bottom Legends - ends */



/* Style for "Others ↓" */

.donut-table {
	font-size: 14px;
	width: calc(100% - 88px);
	margin-left: 32px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
	margin-top: 6px;
}

.donut-table th {
	font-weight: 400
}

.donut-table td {
	border-bottom: 1px solid #EEE;
	border-top: 1px solid #EEE;
}

.data-wrapper {
	width: 100%;
}

.data-wrapper.blurred {
	filter: blur(8px);
	pointer-events: none;
}

.no-data {
	position: absolute;
	top: 45%;
	width: 100%;
	text-align: center;
	color: rgba(0, 0, 0, 0.65);
	font-size: 22px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.65);
}

.filter-no-data {
	margin-left: 20px;
	margin-right: 20px;
	color: rgba(0,0,0,.55);
	font-size: 14px;
	font-weight: 600;
}

.premium {
	top: 50% !important;
}

.line-chart-legend {
	display: inline-block;
	position: absolute;
	/*bottom: -30px;*/
	/*left: 40px;*/
	color: #6500b0;
	font-size: 16px;
	padding-top: 5px;
}
.trending-chart-lagend-label{
	color: rgba(0,0,0,0.85);
	font-size: 16px;
	letter-spacing: 0.26px;
	line-height: 24px;
	padding:0px 5px;
}

.line-chart-legend div {
	display: inline-block;
	box-sizing: border-box;
	height: 5px;
	width: 5px;
	background-color: #6500b0;
	margin-left: 5px;
	margin-bottom: 2px;
}
.trending-chart-legend-checkbox {
	height: 24px;
	width: 24px;
	background-color: #2264A0;
	-webkit-appearance: none;
}
.trending-chart-legend-checkbox:checked{
	content: url('../../images/icons/checkmark.png');
	padding: 4px;
}

.line-chart-legend div:first-of-type {
	margin-left: 16px;
}

[data-tooltip]:before {
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 2px;
    border-color: #222222;
}

[data-tooltip]:hover:before {
    opacity: 1;
    background: #222222;
    margin-top: 35px;
    margin-left: 20px;    
}