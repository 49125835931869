.header-tabs {
	overflow: hidden;
	margin-top: 7px
}

.header-tab {
	padding-right: 32px;
	height: 30px;
	float: left;
	font-size: 16px;
	line-height: 35px;
	margin-right: 8px;
	color: #4e5d78;
	cursor: pointer;
	border-bottom: 5px solid #CED2D9;
}

.header-tab:hover {
	border-bottom: 5px solid #CED2D9;
}

.header-tab.active {
	color: #000000;
	border-bottom: 5px solid #2A7FC9;
}

.tab-title {
	font-size: 16px;
	line-height: 17px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.tab-title title {
	height: 27px;
	border-radius: 2px;
	background-color: #222222;
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 24px;
}

.tab-title[title]:hover::after {
 content: attr(data-title);
    background-color: #8fbc8f;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
}

[data-tooltip]:before {
    /* needed - do not change */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    
    /* customizable */
    /*transition: all 0.15s ease;*/
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 2px;
    border-color: #222222;
}

[data-tooltip]:hover:before {
    /* needed - do not change */
    opacity: 1;
    
    /* customizable */
    background: #222222;
    margin-top: 35px;
    margin-left: 20px;    
}

[data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
}