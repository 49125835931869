/* ----------- Flex ----------------*/

$flex: flex;
$flex-end: flex-end;
$center: center;

/* ----------- Flex ----------------*/

/* ----------- Alignment ------------ */

/* ----------- Alignment ------------ */

/* ------------ Size ----------------*/

$font14: 14px;
$bold: bold;


/* ------------ Size ----------------*/


/* ----------- Colors --------------*/

$black: #000000;
$white: #ffffff;
$page-title: #072457;
