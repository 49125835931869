$sidebar-padding-right: 0px;
$sidebar-collapsed-padding-right: 0px;


.side-bar.collapsed {
	//flex: 0 0 121px;
	padding-right: $sidebar-collapsed-padding-right;
	transition: padding-right 0.15s ease;
}

.side-bar.collapsed ~ .main-container {
	margin-left: 108px;
}

.side-bar.collapsed ~ .main-container > .main-content > div > .top-header {
	left: 124px;
}

.side-bar > div:first-child > div:nth-child(1):after {
	border-bottom: none !important;
}

.side-bar > div:first-child > div:nth-child(1) > h1 {
	color: #082349;
	// margin-left: 38px;
}

.side-bar > div:first-child > div:nth-child(2) > button, .side-bar > div:first-child > div:nth-child(3) > button {
	color: #082349;
}

.side-bar-version {
	padding: 0 20px 0 24px; /*0 24px 20px;*/
	font-size: 14px;
	position: relative;
	top: -20px;

	.subscription-dropdown {
		height: 27px;
		width: 67px;
		float: left;
		.rectangle {
			box-sizing: border-box;
			height: 27px;
			width: 67px;
			border: 1px solid #D9E1E8;
			border-radius: 13.5px;
			background-color: #F9F9F9;
			box-shadow: 0 2px 4px 0 rgba(25,74,116,0.15);
			display: flex;

			.basic {
				height: 17px;
				width: 37px;
				color: #194A74;
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4.5px 2px 4.5px 15px;
			}
		}
	}

	.subscription-dropdown.standard {
		height: 27px;
		width: 113px;
		float: left;
		.rectangle {
			height: 27px;
			width: 113px;
			border-radius: 13.5px;
			background-color: #194A74;
			box-shadow: 0 2px 4px 0 rgba(25,74,116,0.15);
			display: flex;

			.standard {
				height: 17px;
				width: 62px;
				color: rgba(255,255,255,0.88);
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4px 2px 4.5px 15px;
				opacity: 0.9;
				/*cursor: pointer;*/
			}
		}
	}

	.subscription-dropdown.standard.non-pac {
		height: 27px;
		width: 113px;
		float: left;
		.rectangle {
			height: 27px;
			width: 113px;
			border-radius: 13.5px;
			background-color: #194A74;
			box-shadow: 0 2px 4px 0 rgba(25,74,116,0.15);
			display: flex;

			.standard {
				height: 17px;
				width: 62px;
				color: rgba(255,255,255,0.88);
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4px 2px 4.5px 24px;
				opacity: 0.9;
				/*cursor: pointer;*/
			}
		}
	}
	
	.subscription-dropdown.basic {
		height: 27px;
		width: 88px;
		float: left;
		.rectangle {
			height: 27px;
			width: 88px;
			border-radius: 13.5px;
			border: 1px solid #D9E1E8;
			background-color: #F9F9F9;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
			box-sizing: border-box;
			display: flex;

			.basic {
				height: 17px;
				width: 37px;
				color: #194A74;
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4.5px 2px 4.5px 15px;
				/*cursor: pointer;*/
			}
		}
	}

	.subscription-dropdown.internal {
		height: 27px;
		width: 84px;
		float: left;
		.rectangle {
			height: 27px;
			width: 84px;
			border-radius: 13.5px;
			border: 1px solid #D9E1E8;
			background-color: #F9F9F9;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
			box-sizing: border-box;
			display: flex;

			.internal {
				height: 17px;
				width: 56px;
				color: #194A74;
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4.5px 2px 4.5px 15px;
				/*cursor: pointer;*/
			}
		}
	}

	.subscription-dropdown.no-subscription {
		height: 27px;
		width: 84px;
		float: left;
		.rectangle {
			height: 27px;
			width: 144px;
			border-radius: 13.5px;
			border: 1px solid #D9E1E8;
			background-color: #F9F9F9;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
			box-sizing: border-box;
			display: flex;

			.no {
				height: 17px;
				/*width: 56px;*/
				color: #194A74;
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				letter-spacing: 0.2px;
				line-height: 17px;
				margin: 4.5px 2px 4.5px 15px;
				/*cursor: pointer;*/
			}
		}
	}
	
	.chevron {
		height: 10px;
		width: 10px;
		margin: 8px;
		transform: rotate(0deg);
	}

	.chevron.down {
		transform: rotate(180deg);
	}

	.dropdown {
		height: 128px;
		width: 205px;
		border-radius: 5px;
		background-color: #FFFFFF;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
		transition: all .25s ease-in-out;
		/*transform-origin: left top;*/
		padding-left: 0;
		top: 3px;
		overflow: hidden;

		.icon-check-mark {
			font-size: 12px;
			float: right;
			color: #194A74;
			margin-top: 4px;
		}

		.tier-info-basic {
			margin: 21px 14px 0 12px;
			float: left;
		}

		.tier-info-standard {
			margin: 17px 14px 0 12px;
			float: left;
		}

		.dropdown-basic {
			height: 17px;
			width: 37px;
			color: #000000;
			font-family: 'Inter', sans-serif;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 17px;
			display: inline;
		}

		.dropdown-desc {
			height: 18px;
			width: 181px;
			color: rgba(0,0,0,0.66);
			font-family: 'Inter', sans-serif;
			font-size: 12px;
			letter-spacing: 0;
			line-height: 16px;
			float: left;
		}

		.dropdown-standard {
			height: 17px;
			width: 62px;
			color: #000000;
			font-family: 'Inter', sans-serif;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 17px;
			display: inline;
		}

		.dropdown-basic > div:first-child, .dropdown-standard > div:first-child {
			float: left;
		}
	}

	.dropdown.closed {
		color: transparent;
		height: 0;
		/*transform: scale(0);*/
	}

	.version-link {
		height: 19px;
		/*width: 32px;*/
		color: #2264A0;
		font-family: 'Inter', sans-serif;;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 29px;
		float: right;
		cursor: pointer;
	}
}

/*.side-bar-version > div:first-child {
	color: #184a75;
	cursor: pointer;
	text-decoration:underline;
}*/

.side-bar-version a {
	color: #184a75;
	text-decoration: none;
	padding:0;
}

.side-bar-version:after {
	position: absolute;
	content: ' ';
	background: rgb(0, 0, 0);
	width: 25px; /*10%;*/
	/*border-bottom: 2px solid rgb(42, 127, 201);*/ /* to show "---" blue line below it */
	top: 40px;
	left: 24px;
}

.side-bar {
	position: fixed;
	height: 100%;
	//position: static;
	// width: 329px;
	padding-right: $sidebar-padding-right;
	transition: padding-right 0.5s ease;
	background-color: #f2f4f6;
	/*margin-top:40px;*/
	& > div > div > div > div {
		top: 21%;
	}
}