.coverage-widget {
	/*height: auto;*/
	/*grid-column: span 6;*/ /* grid-column: span 6; todo: change to span 6 when adding media load size */
	max-width: 1032px;
	/*width: 1032px;*/
}

.coverage-header {
	font-size: 24px;
	font-weight: 700;
	line-height: 48px;
}

.coverage-body {
	display: flex;
	flex-wrap: wrap;
	width:1032px;
	justify-content:space-between;
	/* display: grid;
	grid-gap: 24px;
	grid-auto-flow: column; */
}

.coverage-body-2 {
	display: flex;
	flex-wrap: wrap;
	width:1032px;
	justify-content: center;
}

.coverage-token {
	min-width: 185px;
	position: relative;
    height: 95px;
    /*flex: 1 1 ;*/
    max-width: 50%;
    margin-bottom: 25px;
    margin-left:24px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.12);
    background-color: #ffffff;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    /*cursor: pointer; */
}
.coverage-body .coverage-token:first-child{
	margin-left: 0px;
}
.coverage-body-2 .coverage-token:first-child{
	margin-left: 0px;
}
.customeTooltip{
	margin-top: 20%;
    position: absolute;
    z-index: 1;
    width: auto;
   right:10%;
    padding: 5px 10px;
    height: 27px;
    background: #000;
    border-radius: 2px;
    color: #FFF;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 17px;
}
.coverage-token-background-fill {
		position: relative;
    height: 100%;
    /* width: 60%; */
    border-radius: 15px 0 0 15px;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-evenly;
}

.coverage-token-image-wrapper {
	position: absolute;
	left: 12px;
	height:48px;
	width: 48px;
	border-radius: 8px;
}

/*.coverage-token-image {*/
/*    position: absolute;*/
/*    top: 35%;*/
/*    left: 35px;*/
/*    height: 30px;*/
/*    -webkit-transform: translateY(-50%);*/
/*    transform: translateY(-50%);*/
/*}*/

.coverage-token-background {
	bottom: 0;
	position: absolute;
	height: 110px;
	width: 187px;
	background-color: white;
	border-radius: 3px;
   box-shadow: 0 1px 2px #cfc9c9;
}

.coverage-token-data {
	text-align: center;
    font-size: 32px;
    line-height: 32px;
    width: 100%;
    font-weight: bold;
}

.coverage-token-data .denominator {
	font-size: 20px;
}

.coverage-token-data .info {
	font-size: 18px;
	line-height: 50px;
	vertical-align: top;
}

.vertical-middle {
	vertical-align: middle;
}

.coverage-token-title {
     bottom: 15px;
    color: #7A7A7A;
    font-size: 15px;
    text-align: center;
    line-height: 17px;
    height: 20px;
    width: 100%;
}

.tooltip-cursor {
    cursor: pointer;
}

[data-pillbox-tooltip]:before {
    /* needed - do not change */
    content: attr(data-pillbox-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    /*transition: all 0.15s ease;*/
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 2px;
    border-color: #222222;
    width: max-content;
}

[data-pillbox-tooltip]:hover:before {
    /* needed - do not change */
    opacity: 1;

    /* customizable */
    background: #222222;
    margin-top: 25px;
    margin-left: -5%;
    z-index: 1;
}

[data-pillbox-tooltip]:not([data-pillbox-tooltip-persistent]):before {
    pointer-events: none;
}

[data-pillbox-tooltip-2]:before {
    /* needed - do not change */
    content: attr(data-pillbox-tooltip-2);
    position: absolute;
    opacity: 0;

    /* customizable */
    /*transition: all 0.15s ease;*/
    padding: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    font-family: 'Inter', sans-serif;
    border-radius: 2px;
    border-color: #222222;
    width: max-content;
}

[data-pillbox-tooltip-2]:hover:before {
    /* needed - do not change */
    opacity: 1;

    /* customizable */
    background: #222222;
    margin-top: 25px;
    margin-left: -45%;
    z-index: 1;
}

[data-pillbox-tooltip-2]:not([data-pillbox-tooltip-2-persistent]):before {
    pointer-events: none;
}