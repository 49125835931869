
.line-chart {
  padding-left: 24px;
  padding-right: 32px;
  &.small-left-padding {
    padding-left: 20px;
  }

  .chartjs-render-monitor {
    height: 282px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .chartjs-render-monitor-pop {
    height: 582px;
    color: #7d8696;
    font-family: "Inter regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
}

.card-body {
  padding: 24px;
  padding-bottom: 0;
  &.min-top {
    padding-top: 25px;
    margin-bottom: 15px;
  }
  &.media-detail-card {
    padding: 25px 0 0 !important;
    width: 780px !important;
  }
  &.trend-card {
    padding: 24px 0 0 !important;
    //width: 1032px !important;
  }
}

.media-detail-card .data-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.chart-wrapper {
  max-height: 285px;
}

.dropdown {
  padding-left: 21px;
  .btn {
    padding-left: 0;
  }
}

.chart-y-axis {
  padding-bottom: 20px;
  padding-left: 7px;
  color: #a9a9a9;
  font-weight: normal;
  font-size: 13px;
}
