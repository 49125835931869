@font-face {
  font-family: 'Inter regular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-Regular.woff2") format("woff2"),
  url("../fonts/Inter/Inter web/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter Semibold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-SemiBold.woff2") format("woff2"),
  url("../fonts/Inter/Inter web/Inter-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Inter Bold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter web/Inter-Bold.woff2") format("woff2"),
  url("../fonts/Inter/Inter web/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Raleway Extrabold';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Raleway/raleway-extrabold-webfont.woff2") format("woff2"),
  url("../fonts/Raleway/raleway-extrabold-webfont.woff") format("woff");
}

@font-face {
  font-family: 'Robot regular';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Robot/roboto-regular-webfont.woff2") format("woff2"),
  url("../fonts/Robot/roboto-regular-webfont.woff") format("woff");
}