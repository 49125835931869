.modal .error-message-popup {
  margin-top: 12%;
  margin-left: 35%;
}

.modal .modal-content .error-message .header {
  font-family: "Inter Bold";
  font-size: 18px;
  color: #194A74;
}

.modal .modal-content .error-message .form-body {
  margin-top: 16px;
}

.modal .modal-content .error-message .buttons-section {
  float: none;
  margin-bottom: 2px;
  margin-top: 32px;
}

.modal .modal-content .error-message .buttons-section .button-primary {
  left: 38%;
  position: relative;
  border-radius: 2px !important;
}

.modal .modal-content .error-message .form-body .note {
  margin-top: 0px;
  margin-bottom: 12px;
}

.modal .modal-content .error-message .form-body .table-wrapper {
  max-height: 197px;
  overflow-y: auto;
}

.modal .error-message-popup .modal-content table {
  margin-bottom: 0px;
}

.modal .error-message-popup .modal-content table td {
  font-family: "Inter SemiBold";
  font-size: 16px;
  color: black;
  vertical-align: middle;
}

.modal .error-message-popup .modal-content table tr {
  height: 41px;
}

.modal .modal-content .error-message {
  padding: 22px !important;
}