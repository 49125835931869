@import "variables.scss";

.clickable {
	cursor: pointer;
}

.coverage-icon {
	margin-right: 7px;
}

.line {
	width: 1px;
	border: 1px solid #eeeeee;
	margin-top: 15px;
	margin-bottom: 15px;
}

.CardFooter {
	color: $white;
}

.hidden {
	visibility: hidden;
}

.feedback-submit {
	width: 187px;
	height: 40px;
	border-radius: 2px;
	background-color: #2a7fc9;
	color: #ffffff;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 24px;
}

.feedback-clear {
	width: 115px;
	height: 40px;
	background-color: #ffffff;
	border: 2px solid #2a7fc9;
	color: #2a7fc9;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-left: 24px;
}

textarea {
	width: 100%;
	-webkit-box-sizing: border-box;
}

.feedback-submit-div {
	padding-top: 48px;
	margin-bottom: 24px;
	margin-right: 24px;
	float: right;
}

.modal-dialog {
	margin-top: 25%;
	margin-left: 28%;
}

.popup {
	position: absolute;
	width: 60%;
	height: 10%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0);
}

.popup_inner {
	position: absolute;
	right: 25%;
	bottom: 25%;
	margin: auto;
	background: white;
}

.popup-modal {
	position: absolute;
	width: 552px;
	height: 176px;
	box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	background-color: #ffffff;
}

.popup_button {
	position: absolute;
	top: 0px;
	right: 0px;
}

.popup-submit {
	width: 115px;
	height: 40px;
	border-radius: 2px;
	border: 2px solid #2a7fc9;
	background-color: #2a7fc9;
	color: #ffffff;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-right: 15px;
	text-align: center;
	margin-top: 24px;
}

.popup-clear {
	width: 115px;
	height: 40px;
	border-radius: 2px;
	border: 2px solid #2a7fc9;
	text-align: center;
	background-color: #ffffff;
	color: #2165a0;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	margin-right: 15px;
	margin-top: 24px;
}

.modal-first {
	width: 552px;
	height: 176px;
	box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
}

.modal-first-body {
	margin-right: 24px;
	float: right;
}

.modal-pop-submit-feedback-text1 {
	width: 504px;
	height: 32px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
	margin-top: 24px;
}

.modal-pop-submit-feedback-text2 {
	width: 504px;
	height: 24px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	padding-top: 8px;
}

.manage-detail .details {
	width: 167px;
}

.trend-image {
    width: 172px;
    height: 248px;
    background-position: center;
    background-size: cover;
	border: 1px solid #e3e3e3;
	border-radius: 15px !important;
	box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.12);
	overflow:hidden;
}

.trend-placeholder-image {
	position: absolute;
	top: 62%;
	left: 13%;
	transform: translate(-50%,-100%);
}

.games .trend-placeholder-image, .live-tv .trend-placeholder-image {
	position: absolute;
	top: 59%;
	left: 13%;
	transform: translate(-50%,-100%);
}

.trend-item-image {
	display: block;
	position: absolute;
	// object-fit: cover;
	height: 248px;
	width: 172px;
	border-radius: 15px;
	opacity: 0.01;
	/*box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.5);*/
}

.trend-alt-title {
	position: absolute;
	top: 63%;
	font-size: 16px;
	width: 167px;
	color: #b4d0ed;
	text-align: center;
	font-weight: 700;
	word-wrap: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 45%;
	padding-left: 3px;
	padding-right: 3px;
}

.games .trend-alt-title, .live-tv .trend-alt-title {
	top: 60%;
}

.trend-item-image-shadow {
	box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.45);
	opacity: 1;
}

.trend-image-title {
	/*width: 590px;*/
	height: 32px;
	color: #000000; /*#072457;*/
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 24px;
}

.trend-performance {
	width: 185px;
	height: 24px;
	color: #000000;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	margin-top: 27px;
	.widget-image {
		margin-left: 0;
		margin-right: 5px;
	}
}

.trend-title-desc {
	width: 145px;
	height: 66px;
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.51px;
	line-height: 22px;
	padding-bottom: 17px;
}

.trend-runtime {
	color: #000000;
	//font-family: "Inter Semibold", sans-serif;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	margin-top: 7px;
}

.ondeck {
	width: 145px;
	color: black;
	font-family: "Inter Semibold", sans-serif;
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0.51px;
	line-height: 21px;
}

.ondeck-text {
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
}

.card-trend {
	width: 1024px;
	height: 482px;
	box-shadow: 0 1px 8px #979797;
	border-radius: 10px;
	background-color: #ffffff;
}

.trend-col {
	flex-grow: 0;
	margin-right: 24px;
	margin-top: 25px;
}

.trend-line {
	width: 600px;
	background-color: #e0e0e0;
	margin-left: 0px;
    margin-bottom: 30px;
}

.popup {
	width: 100%;
	height: 100%;
	margin-left: 50px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10;
}

.popup-shade {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.popup\_inner {
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	border-radius: 20px;
	background: white;
}

.linechart-pop-align {
	padding-top: 125px;
	float: right;
	max-height: 500px;
	max-width: 500px;
	width: 100%;
	margin-left: -30px;
	//max-height:100%;
}

.pop-totalviewers {
	width: 117px;
	height: 24px;
	color: #909090;
	font-family: "Inter regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
}

.pop-totalviewers-value {
	width: 153px;
	height: 28px;
	color: #000000;
	font-family: "Inter regular", sans-serif;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 0.31px;
	padding-top: 7px;
}

.close {
	position: absolute;
	z-index: 999;
	outline: none;
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
}

.close:hover {
	opacity: 1;
}

.close:before,
.close:after {
	position: absolute;
	left: 15px;
	content: ' ';
	height: 33px;
	width: 2px;
	background-color: #333;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

#market-place {
	.card-body {
		padding-left: 0;
		padding-right: 0;
	}
	.widget-frame {
		height: 316px;
	}
	.chart-wrapper,
	.widget-title {
		margin-left: 32px;
	}
	.Entertainment {
		position: relative;
		background-color: white;
		/*max-width: 1032px;*/
		height: 550px;
		box-shadow: 0 1px 2px #cfc9c9;
		border-radius: 3px;
		grid-column: span 2;
		transition: all .6s ease-in-out;
	}
	.tabs-wrapper {
		margin: auto !important;
		.col {
			max-width: 175px;
		}
		.col:nth-child(4) {
			max-width: 253px;
			.airlinesummarycard {
				max-width: 250px;
			}
		}
		.airlinesummarycard {
			margin-right: 0;
			width: auto;
			max-width: 160px;
			.carddatausage {
				font-size: 1.9rem;
			}
		}
	}
}