.modal .tail-popup {
  margin-top: 5%;
  margin-left: 35%;
}

.modal .modal-content .add-tail {
  padding: 24px !important;
}

.modal .modal-content .add-tail form div {
  margin-top: 16px;
}

.modal .modal-content .add-tail form div:nth-child(1) {
  margin-top: 6px;
}

.modal .modal-content .add-tail form div:nth-child(2) {
  margin-top: 6px;
}

.modal .modal-content .add-tail .button-primary, .modal .modal-content .add-tail .button-secondary {
  border-radius: 2px !important;
}

.modal .modal-content .add-tail table th, .modal .modal-content .add-tail table td {
  font-family: 'Inter SemiBold', sans-serif;
  padding: 4px 9px 4px 9px;
}

.modal .modal-content .add-tail table tbody tr:nth-child(even) {
  background-color: #ededed;
}

.modal .modal-content .add-tail .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
  color: #000000; /*#194A74;*/
}

.modal .modal-content .add-tail .form-body {
  margin-top: 24px;
}

.modal .modal-content .add-tail .form-body .notification {
  border-radius: 2px;
  background-color: #F9E9E9;
  margin-bottom: 16px;
  padding: 12px 16px;
}

.modal .modal-content .add-tail .form-body .notification .title {
  height: 23px;
  width: 420px;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}

.modal .modal-content .add-tail .form-body .notification .description {
  width: 420px;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.modal .modal-content .add-tail .form-body .table {
  margin-bottom: 0;
}

.modal .modal-content .add-tail .form-body .airline {
  margin-bottom: 6px;
  margin-top: 12px;
}

.modal .modal-content .add-tail .form-body .airline .edit-label {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  opacity: 0.6;
}

.modal .modal-content .add-tail .form-body .airline .value {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  opacity: 1;
  margin-left: 6px;
}

.modal .modal-content .add-tail .form-body .add-label {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}

.modal .modal-content .add-tail .form-body .input-section {
  margin-top: 24px;
}

.modal .modal-content .add-tail .form-body .edit-tier-section {
  margin-top: 16px;
}

.modal .modal-content .add-tail .form-body .input-section .input-label {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}

.modal .modal-content .add-tail .form-body .input-section .multi-label {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  margin-bottom: 4px;
}

.modal .modal-content .add-tail .form-body .input-section .multi-label span:last-child
{
  font-family: 'Inter Regular', sans-serif;
}

.modal .modal-content .add-tail .form-body .input-section .input-sub-label {
  font-family: 'Inter Regular', sans-serif;
  font-size: 12px;
  margin-bottom: 12px;
}

.modal .modal-content .add-tail .form-body .input-section .month-dropdown {
  padding-right: 5px;
}

.modal .modal-content .add-tail .form-body .input-section .year-dropdown {
  padding-left: 5px;
}

.modal .modal-content .add-tail .buttons-section {
  float: right;
  margin-top: 32px;
}

.modal .modal-content .add-tail .buttons-section .spinner-border {
  width: 15px;
  height: 15px;
  border-width: 1px;
}

.modal .modal-content .add-tail .form-body .input-section .inactive-license-msg {
  /*border-left: 4px solid rgb(202, 43, 43);*/
  padding: 12px 16px;
  background-color: rgb(249, 233, 233);
  border-radius: 2px;
  width: 452px;
  font-size: 14px;
  line-height: 19px;
}

.margin-left-16 {
  margin-left: 16px;
}

.form-check-input {
  height: 16px;
  width: 16px;
}

.form-check-label {
  margin-left: 3px;
}