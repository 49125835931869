.insights-alert {
	 right: 24px;
	 margin-bottom: 24px;
	 width: 315px;
	 left: auto;
	 background-color: #e3ecf3;
	 border-color: rgba(25, 74, 116, 0.6);
	 padding-left: 0px;
	 padding-right: 0px;
}

.insights-alert .close-alert {
	 float: right;
	 padding: 4px 16px 0 16px;
	 cursor: pointer;
}

.insights-alert .arrow {
	 float: left;
	 padding: 4px 16px 0 16px;
}

.loading-spinner {
    margin-left: 16px;
    margin-right: 16px; 
}

.text-body {
	margin-left: 52px;
}

.text-body .title {
	font-family: 'Inter SemiBold';
}
 
.text-body .filename {
	font-size: 14px;
    overflow: hidden;
    width: 243px;
    white-space: nowrap;
    text-overflow: ellipsis;
} 