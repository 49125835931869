.header {
   .page-title {
      padding-left: 0;
   }  
}

.components-wrapper {
   .disable-date {
       display: none;
       width: 280px;
       position: absolute;
       z-index: 999;
       height: 50px;
       right: 440px;
       background-color: #ccc;
       opacity: .6;
       top: -88px;
   }
}

#paginator {
   display: flex;
   font-family: 'Inter regular', sans-serif;
   font-size: 16px;
   font-weight: 400;
   line-height: 24px;
   margin-bottom: 10px;
   
   span {
      padding: 6px 0 0 5px;
   }
   
   span:first-child {
      padding: 6px 5px 0 0;
   }
   
   span.total-pages {
      padding-left: 5px;
   }
   
   .arrows-buttons {
       margin: 7px 0 0 20px;
       display: flex;
       position: relative;
       cursor: pointer;
      
      i {
         transform: scale(1.22, .9);
      
         &:first-child {
            margin-right: 25px;
         }
         
         &.disabled {
           opacity: .3;
         }
         
      }
      
      div.disable-cover {
         position: absolute;
         z-index: 999;
         width: 22px;
         height: 18px;
         left: -3px;
         
         &.forward {
            right: -3px;
            left: initial;
         }
      }
   }
   
   .workbench-select-container {
      margin: 0;
      color: #000;
      font-size: 16px;
      
      .workbench-select__control {
         width: 60px;
      }
      
      .workbench-select__indicator-separator {
         display: none;
      }
      
      .workbench-select__dropdown-indicator {
         padding: 2px;
         width: 20px;
         &::before {
           font-family: "Font Awesome 5 Free", sans-serif;
           font-weight: 900;
           content: "\f0dd";
           margin-top: -4px;
         }   
         svg {
            display: none;
         }
      }
      
      .workbench-select__menu {
         width: 70px !important;
         .workbench-select__menu-notice--no-options {
            font-size: 12px;
            color: black;
         }
         
      }
   }
   
   .total-pages {
      margin-right: 15px;
   }
}