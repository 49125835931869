.widget-frame.screen-transition {
  .data-wrapper.blurred {
	-webkit-filter: blur(8px);
	filter: blur(8px);
	pointer-events: none;
  }

  .screen-transition-wrapper:nth-child(3) {
	.screen-transition-item {
	  color: black;
	}
  }


  .screen-transition-wrapper {
	width: 100%;
	border: 1px solid #ADADAD;
	border-radius: 5px;

	.screen-transition-header {
	  font-size: 18px;
	}

	.screen-transition-items {
	  overflow-y: scroll;
	  height: 450px !important;
	  .screen-transition-1 {
		margin-bottom: -15px;
		p[aria-label="no-next-lavel"]{
			font-weight:normal;
			 font-size: 16px;
			 color:#000000;
		}
		span[aria-label="no-next-lavel"]{
		 font-size: 20px;
		 font-weight: 600;
		 color:#000000;
		}
		.screen-transition-item-name{
		  padding-bottom: 12px;
		}
		.screen-transition-item-value {
		  margin-top: -5px;
		}
		.screen-transition-item-number {
		  margin-top: -25px;

		  div {
			width: 25px !important;
			height: 25px !important;
			border-radius: 5px;
			background-color: #ededed;
			line-height: 25px;
			text-align: center;
			font-size: 12px;
		  }
		  div[aria-label="no-next-lavel"]{
		  	width: 25px !important;
			height: 25px !important;
			border-radius: 5px;
			background-color: #78B1E2;
			line-height: 25px;
			text-align: center;
			font-size: 12px;
			color:#fff;
		  }
		  
		}
	  }

	  & > li {
		color: #7A7A7A;
		line-height: 24px;
		& > span {
		  font-size: 12px;
		}
	  }
	  & > li:hover {
		background-color: #F9F9F9;
		cursor: pointer;
		.detail, .visits-label, .arrow {
		  visibility: visible !important;
		}

	  }
	  & > li.active {
		background-color: #2264A0;
		color: white;
		font-weight: bold;
		.screen-transition-item-number div {
		  background-color: #78B1E2;
		}
		span.screen-transition-item-value-number {
		  font-size: 20px;
		}
	  }
	}

  }
}
