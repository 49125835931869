.modal .license-popup {
  margin-top: 12%;
  margin-left: 35%;
}

.modal .modal-content .add-license {
  padding: 24px !important;
}

.modal .license-popup .modal-content {
  width: 520px;
}

.modal .modal-content .add-license .button-primary, .modal .modal-content .add-license .button-secondary {
  border-radius: 2px !important;
}

.modal .modal-content .add-license table th, .modal .modal-content .add-license table td {
  padding-left: 25px;
  padding-right: 25px;
}

.modal .modal-content .add-license .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
  color: #000000; /*#194A74;*/
}

.modal .modal-content .add-license .form-body {
  margin-top: 24px;
}

.modal .modal-content .add-license .form-body .airline {
  margin-bottom: 6px;
}

.modal .modal-content .add-license .form-body .airline .edit-label {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  opacity: 0.6;
}

.modal .modal-content .add-license .form-body .airline .value {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  opacity: 1;
  margin-left: 6px;
}

.modal .modal-content .add-license .form-body .add-label {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}

.modal .modal-content .add-license .form-body .input-section {
  margin-top: 24px;
}

.modal .modal-content .add-license .form-body .input-section .input-label {
  font-family: 'Inter SemiBold', sans-serif;
  font-size: 16px;
  margin-bottom: 6px;
}

.modal .modal-content .add-license .form-body .input-section .month-dropdown {
  padding-left: 5px;
}

.modal .modal-content .add-license .form-body .input-section .year-dropdown {
  padding-right: 5px;
}

.modal .modal-content .add-license .buttons-section {
  float: right;
  margin-top: 32px;
}

.modal .modal-content .add-license .buttons-section .spinner-border {
  width: 15px;
  height: 15px;
  border-width: 1px;
}

.margin-left-16 {
  margin-left: 16px;
}
