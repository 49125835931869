.horizontal-bar-chart-wrapper {
	width: 100%;
}

.horizontal-bar {
	font-size: 14px;
	width: calc(100% - 65px);
	margin-left: 22px;
	padding-bottom: 32px;
	empty-cells: hide;
	border-collapse:collapse;
}

.horizontal-bar th {
	font-weight: 400
}

.horizontal-bar-chart {
	display: inline-block;
	width: calc(50% - 32px);
	background-color: green;
}

.item-number {
	font-weight: 600;
	line-height: 32px;
	/*min-width: 20px;*/
}

.upper-case {
	text-transform: uppercase;
}

.item-title {
	position: relative;
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	height: 34px;
	max-width: 136px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/*line-height: 32px;*/
}

.item-title .item-title-tooltip {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 16px;
	position: absolute;
	z-index: 1;
	left: 0px;
	margin-left: 32px;
	margin-top: 32px;
	/*box-shadow: 0 1px 8px #979797;*/
}

.item-title .item-title-tooltip::before {
	/*content: url(../../images/tooltipArrow.png);*/
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent black transparent;
}

.item-title.truncated:hover .item-title-tooltip {
	visibility: visible;
}

.route-character {
	display: inline-block;
	color: white;
	height: 24px;
	width: 18px;
	background-color: black;
	text-align: center;
	line-height: 24px;
	margin-right: 1px;
	border-radius: 2px;
}

.route-character.dash {
	color: black;
	background-color: white;
}

.route-character-break {
	position: absolute;

	top: 50%;
	width: 45%;
	height: 1px;
	/*	background-color: white; */

}

.route-character-break.left {
	left: 0;
}

.route-character-break.right {
	right: 0;
}

.item-change-arrow {
	/*opacity: 0.57;*/
	padding-left: 16px;
	padding-right: 3px;
}

.item-change-arrow.up {
	color: #225522;
}

.item-change-arrow.down {
	color: #FF0000;
}

.item-change-number {
	/*width: 65px;*/
	/*font-size: 16px;*/
	font-size: 14px;
	/*opacity: 0.57;*/
	/*text-align: center;
	font-weight: bold;*/
	margin-right: -8px;
	white-space: nowrap;
}

.item-data {
	width: 80px;
	text-align: right;
}

.item-bar {
	width: 270px;
	padding-left: 16px;
}

.hide-bar {
	display: none;
}

.bar-chart-rect {
	margin-left: 8px;
	height: 24px;
	/*background-image: linear-gradient(270deg, #4790d1 0%, #b4d0ed 100%);*/
	transition: all .6s ease-in-out;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}
.bar-chart-rect-inner-text{
	font-size: 16px;
	letter-spacing: 0;
  	line-height: 20px;
  	float:right;
  	margin-top: 3px;
  	margin-right:5px;
  	margin-left:5px;
  	text-shadow: 0px 0px 16px #ffffffd4;
  	white-space: nowrap;
    text-overflow: ellipsis;
}

.chart-tooltip > .tooltip-inner {
	max-width: 100%;
}