.modal .end-license-popup {
  margin-top: 12%;
  margin-left: 25%;
}

.modal .end-license-popup .modal-content {
  width: 865px;
}

.modal .end-license-popup .modal-content table {
  margin-bottom: 0;
}

.modal .end-license-popup .modal-content table th {
  font-family: 'Inter Regular', sans-serif;
  font-size: 16px;
  /*opacity: 0.66;*/
}

.modal .end-license-popup .modal-content table tbody tr {
  background-color: #e0e0e0;
}

.modal .end-license-popup .modal-content table th:first-child {
  width: 20%;
}

.modal .end-license-popup .modal-content table th:nth-child(2) {
  width: 16%;
}

.modal .end-license-popup .modal-content table th:nth-child(3) {
  width: 14%;
}

.modal .end-license-popup .modal-content table th:nth-child(4), .modal .end-license-popup .modal-content table th:nth-child(5) {
  width: 25%;
}

.modal .end-license-popup .modal-content table td {
  font-family: '"Inter SemiBold', sans-serif;
  font-size: 16px;
  color: black;
}

.modal .modal-content .end-license {
  padding: 22px !important;
}

.modal .modal-content .end-license .wrapper {
  margin-left: 18px;
  margin-right: 18px;
}

.modal .modal-content .end-license .wrapper .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
  color: #000000; /*#194A74;*/
}

.modal .modal-content .end-license .wrapper .top-info, .modal .modal-content .end-license .wrapper .bottom-info {
  font-family: 'Inter Regular', sans-serif;
  margin-top: 16px;
  font-size: 16px;
}

.modal .modal-content .end-license .wrapper .table-wrapper {
  margin-top: 16px;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.modal .modal-content .end-license .wrapper .buttons-wrapper {
  float: right;
  margin-top: 32px;
}

.modal .modal-content .end-license .wrapper .buttons-wrapper .spinner {
  width: 15px;
  height: 15px;
  border-width: 1px;
}

.margin-left-16 {
  margin-left: 16px;
}

.button-secondary, .button-primary {
  border-radius: 0 !important;
}