.modal .disclaimer-popup {
  margin-top: 12%;
  margin-left: 33%;
}

.modal .disclaimer-popup .modal-content {
  width: 552px;
}

.modal .modal-content .disclaimer {
  padding: 22px !important;
}

.modal .modal-content .disclaimer .wrapper {
  margin-left: 18px;
  margin-right: 18px;
}

.modal .modal-content .disclaimer .wrapper .header {
  font-family: 'Inter Bold', sans-serif;
  font-size: 24px;
  color: #000000; /*#194A74;*/
}

.margin-left-16 {
  margin-left: 16px;
}

.button-secondary, .button-primary {
  border-radius: 0 !important;
}

.modal .modal-content .disclaimer .wrapper .form-body {
  margin-top: 8px;
}

.modal .modal-content .disclaimer .buttons-section {
  float: none;
  margin-bottom: 2px;
  margin-top: 12px;
}

.modal .modal-content .disclaimer .buttons-section .button-primary {
  left: 38%;
  position: relative;
  border-radius: 2px !important;
}

.modal .modal-content .disclaimer .wrapper .form-body .note {
  margin-top: 0px;
  margin-bottom: 12px;
}