.dd-wrapper {
  user-select: none;
  position: relative;
  width: 180px;
  height: 40px;
}

.dd-header {
  display: flex;
  align-items: left;
  /*justify-content: space-between;*/
  line-height: 40px;
  cursor: pointer;
  position: relative;
}

.dd-header-arrow {
  /*font-size: 1px;*/
  color: #2A7FC9;
  transform: rotate(90deg);
}

.dd-header-title {
  margin-left: 24px;
  margin-right: 5px;
  font-size: 14px;
  color: #2A7FC9;
}

.angle-down {
  color: #000;
  margin-right: 20px;
}

.dd-list {
  margin: 0;
  list-style: none;
  z-index: 10;
  position: absolute;
  width: 105%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: rgba(255,255,255,1);
  box-shadow: 0 1px 2px #CFC9C9;
  font-weight: 400;
  padding: 15px 0;
  max-height: 215px;
    bottom: -15px;
    left: 8px;
  /*overflow-y: scroll;*/
  -webkit-overflow-scrolling: touch;
}

.dd-list-item {
  color: #909090;
  list-style: none;
  width: 100%;
  font-size: 14px;
  padding: 8px 16px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dd-list-item-check {
  display: inline-block;
  margin-left: 8px;
}

.dd-list-item.selected {
  color: #2A7FC9;
}

.dd-list-item:hover {
  color: black;
  background-color: #F4F6F8;
  width: 100%;
}

